<template>
  <div class="box">
    <div class="title">流通趋势图</div>
    <div class="main" ref="main"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  data() {
    return {
      myChart: null,
      option: {
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          show: true,
          backgroundColor: 'rgba(8, 24, 81, .49)',
          borderWidth: 0,
          top: 40,
          left: 50,
          right: 0
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLine: {
            lineStyle: {
              color: '#D4D4DD'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#D4D4DD'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#001962'
            }
          },
        },
        color: ['#008EFF', '#32FBB0'],
        series: [
          {
            name: '借阅量',
            data: [],
            type: 'line',
            smooth: true,
            symbol: 'none',
          },
          {
            name: '归还量',
            data: [],
            type: 'line',
            smooth: true,
            symbol: 'none',
          },
        ],
        legend: {
          show: true,
          right: 0,
          data: [
            {
              name: '借阅量',
              icon: 'circle',
              textStyle: {
                color: '#fff'
              },
            },
            {
              name: '归还量',
              icon: 'circle',
              textStyle: {
                color: '#fff'
              }
            },
          ]
        }
      }
    };
  },
  mounted() {
    const chartDom = this.$refs.main;
    this.myChart = echarts.init(chartDom);
    this.init();

    this.myChart.setOption(this.option);
  },
  methods: {
    init() {
      this.$axios.get('/dashboard/query-record').then(res => {
        this.option.xAxis.data = res.data.map(item => item.day);
        this.option.series[0].data = res.data.map(item => item.off_count);
        this.option.series[1].data = res.data.map(item => item.on_count);
        this.myChart.setOption(this.option);
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  margin-top: .4rem;
}
.main {
  height: 2.7rem;
}
</style>