<template>
  <div class="box">
    <div class="horn-top"></div>
    <div class="horn-bottom"></div>
    <div class="title">数据统计</div>
    <div class="list">
      <div class="item">
        <div class="data">{{devices}}台</div>
        <div class="text">接入设备</div>
      </div>
      <div class="item">
        <div class="data">{{users}}人</div>
        <div class="text">认证用户</div>
      </div>
      <div class="item">
        <div class="data">{{fiels}}页</div>
        <div class="text">原文附件</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      devices: 0,
      users: 0,
      fiels: 0,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.$axios.get('/dashboard/total-device').then(res => {
        this.devices = res.data.count;
      });
      this.$axios.get('/dashboard/total-identity-user').then(res => {
        this.users = res.data.count;
      });
      this.$axios.get('/dashboard/total-files').then(res => {
        this.fiels = res.data.count;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  justify-content: space-between;
  padding: .5rem .12rem .3rem;
  .item {
    width: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .data {
      width: 1rem;
      height: 1rem;
      font-size: .18rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      overflow: hidden;
      background-size: 100% 100%;
    }
    &:nth-child(1) .data {
      background-image: url('@/assets/blue.png');
    }
    &:nth-child(2) .data {
      background-image: url('@/assets/yellow.png');
    }
    &:nth-child(3) .data {
      background-image: url('@/assets/purple.png');
    }
    .text {
      font-size: .16rem;
      margin-top: .18rem;
    }
  }
}
</style>