<template>
  <div class="box">
    <div class="title">存量统计</div>
    <div class="total">
      <span class="item" :class="{number: item !== ','}" v-for=" (item, index) in formatter(all).split('')" :key="index">{{ item }}</span>
    </div>
    <div class="list">
      <div class="item">
        <div class="icon">
          <div class="img"><img src="@/assets/p1.png" alt=""></div>
        </div>
        <div>
          <div class="value">{{all}}</div>
          <div class="text">总存量</div>
        </div>
      </div>
      <div class="item">
        <div class="icon">
          <div class="img"><img src="@/assets/p2.png" alt=""></div>
        </div>
        <div>
          <div class="value">{{on}}</div>
          <div class="text">当前存量</div>
        </div>
      </div>
      <div class="item">
        <div class="icon">
          <div class="img"><img src="@/assets/p3.png" alt=""></div>
        </div>
        <div>
          <div class="value">{{off}}</div>
          <div class="text">当前借阅</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      all: 0,
      on: 0,
      off: 0,
    };
  },
  created() {
    this.init();
  },
  methods: {
    formatter(number) {
      const numbers = number.toString().split('').reverse();
      const segs = [];
      while (numbers.length) segs.push(numbers.splice(0, 3).join(''));
      return segs.join(',').split('').reverse().join('');
    },
    init() {
      this.$axios.get('/dashboard/total-store').then(res => {
        this.all = res.data.all;
        this.on = res.data.on;
        this.off = res.data.off;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.total {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: .27rem;
  .item{
    font-size: .2rem;
    color: #C3CAD9;
    &.number {
      background-color: #152A6F;
      font-size: .6rem;
      color: #02A3F7;
      font-weight: bold;
      line-height: .8rem;
      margin: 0 .075rem;
      width: .6rem;
    }
  }
}
.list {
  display: flex;
  justify-content: space-around;
  margin-top: .23rem;
  .item {
    display: flex;
    align-items: center;
    .icon {
      width: .81rem;
      height: .81rem;
      border: 1px solid;
      padding: .1rem;
      border-radius: 50%;
      margin-right: .15rem;
      .img {
        border-radius: 50%;
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &:nth-child(1) {
      .icon {
        border-color: #194975;
        .img {
          background-color: #194975;
          img {
            width: .34rem;
          }
        }
      }
    }
    &:nth-child(2) {
      .icon {
        border-color: #564F3B;
        .img {
          background-color: #564F3B;
          img {
            width: .38rem;
          }
        }
      }
    }
    &:nth-child(3) {
      .icon {
        border-color: #166B66;
        .img {
          background-color: #166B66;
          img {
            width: .27rem;
          }
        }
      }
    }
    .value {
      font-size: .18rem;
      color: #02A4FE;
      font-weight: bold;
    }
    .text {
      font-size: .12rem;
      color: #B0B4C3;
      margin-top: .07rem;
    }
  }
}
</style>