import Vue from 'vue'
import App from './App.vue'
import dataV from '@jiaminghi/data-view'
import ant from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import axios from 'axios';

Vue.use(dataV)
Vue.use(ant);

const instance = axios.create({
  baseURL: '/openapi',
});
instance.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('baseURL')) {
      config.baseURL = `${localStorage.getItem('baseURL')}/openapi`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

Vue.prototype.$axios = instance;

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
