<template>
  <div id="data-view">
    <dv-full-screen-container>
      <topHeader @timeChange="timeChange" />
      <div class="body">
        <div class="left-content">
          <datasSatistics ref="datasSatistics" />
          <ListingNotice ref="ListingNotice" />
        </div>
        <div class="center-content">
          <Stock ref="Stock" />
          <CirculationTrend ref="CirculationTrend" />
          <div class="stock">
            <EquipStock ref="EquipStock" />
            <OwnerStock ref="OwnerStock" />
          </div>
        </div>
        <div class="right-content">
          <BorrowingVolume ref="BorrowingVolume" />
          <DeviceVolume ref="DeviceVolume" />
          <AttributedStock ref="AttributedStock" />
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import topHeader from './topHeader.vue'
import datasSatistics from './datasSatistics.vue'
import ListingNotice from './ListingNotice'
import Stock from './Stock.vue'
import CirculationTrend from './CirculationTrend.vue'
import BorrowingVolume from './BorrowingVolume.vue'
import DeviceVolume from './DeviceVolume.vue'
import AttributedStock from './AttributedStock.vue'
import EquipStock from './EquipStock.vue'
import OwnerStock from './OwnerStock.vue'
export default {
  name: 'index',
  components: {
    topHeader,
    datasSatistics,
    ListingNotice,
    Stock,
    CirculationTrend,
    BorrowingVolume,
    DeviceVolume,
    AttributedStock,
    EquipStock,
    OwnerStock,
  },
  data() {
    return {
      intervalId: null,
    };
  },
  mounted() {
    this.intervalId = setInterval(this.init, Number(localStorage.getItem('time') || 5) * 1000);
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  methods: {
    init() {
      this.$refs.datasSatistics.init();
      this.$refs.ListingNotice.init();
      this.$refs.Stock.init();
      this.$refs.CirculationTrend.init();
      this.$refs.EquipStock.init();
      this.$refs.OwnerStock.init();
      this.$refs.BorrowingVolume.next();
      this.$refs.DeviceVolume.next();
      this.$refs.AttributedStock.init();
    },
    timeChange(value) {
      clearInterval(this.intervalId);
      this.intervalId = setInterval(this.init, value * 1000);
    }
  }
}
</script>

<style lang="scss">
#data-view {
  width: 100%;
  height: 100%;
  background: #000;
  color: #fff;
  #dv-full-screen-container {
    background-image: url('@/assets/bg.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
  }
  .body {
    flex: 1;
    padding: .5rem;
    display: flex;
    overflow: hidden;
    .left-content, .right-content {
      width: 4.94rem;
      display: flex;
      flex-direction: column;
      margin: -.1rem 0;
      overflow: hidden;
      .box {
        padding: .2rem;
        background-color: rgba(7, 23, 90, .2);
        border: .02rem solid rgba(32, 69, 142, .2);
        position: relative;
        margin: .1rem 0;
        .horn-top {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          display: flex;
          justify-content: space-between;
          &::before {
            content: '';
            width: .13rem;
            height: .13rem;
            background: url('@/assets/horn.png');
            background-size: 100% 100%;
          }
          &::after {
            content: '';
            width: .13rem;
            height: .13rem;
            background: url('@/assets/horn.png');
            background-size: 100% 100%;
            transform: rotate(90deg);
            transform-origin: 50% 50%;
          }
        }
        .horn-bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          display: flex;
          justify-content: space-between;
          &::before {
            content: '';
            width: .13rem;
            height: .13rem;
            background: url('@/assets/horn.png');
            background-size: 100% 100%;
            transform: rotate(-90deg);
            transform-origin: 50% 50%;
          }
          &::after {
            content: '';
            width: .13rem;
            height: .13rem;
            background: url('@/assets/horn.png');
            background-size: 100% 100%;
            transform: rotate(180deg);
            transform-origin: 50% 50%;
          }
        }
        .title {
          font-size: .18rem;
          border-left: .02rem solid #fff;
          padding-left: .07rem;
        }
      }
    }
    .right-content {
      .box {
        flex: 1;
        display: flex;
        flex-direction: column;
        .main {
          flex: 1;
          margin-top: .2rem;
        }
      }
    }
    .center-content {
      flex: 1;
      margin: 0 .2rem;
      background: url('@/assets/bg2.png') no-repeat top / 100% 100%;
      padding: .5rem .3rem;
      display: flex;
      flex-direction: column;
      .title {
        font-size: .18rem;
        border-left: .02rem solid #fff;
        padding-left: .07rem;
      }
      .stock {
        display: flex;
        margin: 0 -.1rem;
        flex: 1;
        & > div {
          margin: 0 .1rem;
          flex: 1;
          flex: 1;
          display: flex;
          flex-direction: column;
          .main {
            flex: 1;
            margin-top: .2rem;
          }
        }
      }
    }
  }
}
</style>