<template>
  <div class="box">
    <div class="horn-top"></div>
    <div class="horn-bottom"></div>
    <div class="title">上架通知</div>
    <div class="list">
      <dv-scroll-board :config="config" :key="key" style="height:100%" />
      <!-- <div class="item" v-for="(item, index) in 20" :key="index">
        <div class="index">{{index+1}}</div>
        <div class="name">XXXXXXX档案上架</div>
        <div class="date">2023/2/21  11:31</div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      key: Date.now(),
      timer: null,
      config: {
        rowNum: 14,
        // columnWidth: [60],
        oddRowBGC: '#0E214B',
        evenRowBGC: '#09072B',
        hoverPause: false,
        data: [
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
          // [1, 'XXXXXXX档案上架', '2023/2/21  11:31'],
        ]
      },
      current: 0,
    }
  },
  created() {
    this.init();
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    init() {
      if (this.current >= this.config.data.length) {
        this.$axios.get('/dashboard/query-on').then(res => {
          this.config.data = res.data.map((item, index) => ([index + 1, item.name, item.date]));
          this.$nextTick(() => {
            this.key = Date.now();
            this.current = this.config.data.length < this.config.rowNum ? this.config.data.length : this.config.rowNum;
            this.timer = setInterval(() => {
              this.current = this.current + 1;
            }, this.config.waitTime || 2000);
          });
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.list {
  margin-top: .24rem;
  flex: 1;
  overflow: auto;
  .item {
    display: flex;
    padding: 0 .3rem;
    font-size: .16rem;
    color: #4EB4FF;
    line-height: .36rem;
    background-color: #0E214B;
    &:nth-child(even) {
      background-color: #09072B;
    }
    .index {
      width: .3rem;
    }
    .name {
      flex: 1;
      width: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0 .3rem;
    }
  }
  .dv-scroll-board::v-deep {
    .rows {
      .row-item {
        padding: 0 .2rem;
        font-size: .16rem;
        color: #4EB4FF;
        .ceil:first-child {
          width: .5rem !important;
        }
        .ceil:nth-child(2) {
          width: auto !important;
          flex: 1;
          margin: 0 .2rem;
        }
        .ceil:last-child {
          width: auto !important;
        }
      }
    }
  }
}
</style>