<template>
  <div class="top-header">
    <div class="center-title" data-text="大数据展示系统">大数据展示系统</div>
    <img src="@/assets/decoration.png" class="left-decoration" alt="">
    <img src="@/assets/decoration2.png" class="right-decoration" alt="">
    <div class="date">{{ date }}</div>
    <div class="setup" @click="visible = true">
      <img src="@/assets/setup.png" alt="">设置
    </div>
    <a-modal
      title="设置"
      :visible="visible"
      width="7rem"
      :footer="null"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="form">
        <div class="form-item">
          <div class="label">API地址:</div>
          <div class="value">
            <a-input v-model="baseURL" @change="baseURLChange" />
          </div>
        </div>
        <div class="form-item">
          <div class="label">首页数据刷新时间设置(s):</div>
          <div class="value">
            <a-input-number v-model="time" @change="timeChange" />
          </div>
        </div>
        <div class="form-item">
          <div class="label">排行展示日期设置:</div>
          <div class="value">
            <a-input-number v-model="month" @change="monthChange" />
          </div>
        </div>
        <!-- <div class="form-item">
          <div class="label">借阅量排行展示日期设置:</div>
          <div class="value">
            <a-range-picker
              :placeholder="['开始时间', '结束时间']"
              format="YYYY-MM"
              :value="range1"
              :mode="mode1"
              valueFormat="YYYY-MM"
              @panelChange="handlePanelChange1"
              @change="handleDateChange1"
            />
          </div>
        </div>
        <div class="form-item">
          <div class="label">设备使用量展示日期设置:</div>
          <div class="value">
            <a-range-picker
              :placeholder="['开始时间', '结束时间']"
              format="YYYY-MM"
              :value="range2"
              :mode="mode1"
              @panelChange="handlePanelChange2"
              @change="handleDateChange2"
            />
          </div>
        </div> -->
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      date: '',
      intervalId: null,
      visible: false,
      time: Number(localStorage.getItem('time')) || 5,
      month: Number(localStorage.getItem('month')) || 3,
      range1: localStorage.getItem('range1').split(','),
      range2: localStorage.getItem('range2').split(','),
      mode1: ['month', 'month'],
      baseURL: localStorage.getItem('baseURL'),
    }
  },
  mounted() {
    moment.locale('zh-cn');
    this.setDate()
    this.intervalId = setInterval(this.setDate, 1000);
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  methods: {
    setDate() {
      this.date = moment().format('YYYY年M月D日 dddd HH:MM');
    },
    handleOk() {

    },
    handleCancel() {
      this.visible = false;
    },
    timeChange(value) {
      localStorage.setItem('time', value);
      this.$emit('timeChange', value);
    },
    monthChange(value) {
      localStorage.setItem('month', value);
    },
    handleDateChange1(value) {
      this.range1 = value.map(val => moment(val).format('YYYY-MM'));
      localStorage.setItem('range1', this.range1);
    },
    handlePanelChange1(value, mode) {
      this.range1 = value.map(val => moment(val).format('YYYY-MM'));
      localStorage.setItem('range1', this.range1);
      this.mode1 = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]];
    },
    handleDateChange2(value) {
      this.range2 = value.map(val => moment(val).format('YYYY-MM'))
      localStorage.setItem('range2', this.range2);
    },
    handlePanelChange2(value, mode) {
      this.range2 = value.map(val => moment(val).format('YYYY-MM'));
      localStorage.setItem('range2', this.range2);
      this.mode1 = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]];
    },
    baseURLChange(e) {
      localStorage.setItem('baseURL', e.target.value);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.top-header{
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: .57rem;
  .center-title{
    position: absolute;
    font-size: .4rem;
    font-weight: bold;
    // left: 50%;
    // top: 15px;
    text-align: center;
    width: 100%;
    line-height: .57rem;
    // transform: translateX(-50%);
    color: transparent;
    text-shadow: 0px 3px 7px #083352;
    text-transform: helvetica;
  }
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    .center-title {
      background: linear-gradient(180deg, #FFFFFF 0%, #78D4F7 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: none !important;
      &::after {
        background: none;
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: -1;
        text-shadow: 0px 3px 7px #083352;
      }
    }
  }
  .left-decoration,
  .right-decoration {
    width: 35.7%;
  }
  .date {
    position: absolute;
    left: .5rem;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: .16rem;
    color: #78D4F7;
  }
  .setup {
    position: absolute;
    right: .5rem;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: .16rem;
    color: #78D4F7;
    cursor: pointer;
    img {
      width: .17rem;
      margin-right: .08rem;
    }
  }
}
.form {
  padding: .5rem;
  .form-item {
    display: flex;
    margin-bottom: .5rem;
    .label {
      width: 12em;
      font-size: .24rem;
      text-align: right;
      margin-right: 1em;
    }
    .value {
      flex: 1;
      & > div {
        width: 100%;
      }
    }
  }
}
</style>
