<template>
  <div class="box">
    <div class="horn-top"></div>
    <div class="horn-bottom"></div>
    <div class="title">借阅量排行</div>
    <div class="main">
      <div class="arrow">
        <div class="item" @click="prev"><img src="@/assets/arrow-left.png" alt=""></div>
        <div class="item" @click="next"><img src="@/assets/arrow-right.png" alt=""></div>
      </div>
      <div class="echarts" ref="echarts"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import moment from 'moment'

export default {
  data() {
    return {
      year: null,
      month: null,
      index: 0,
      range: [],
      myChart: null,
      option: {
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLine: {
            lineStyle: {
              color: '#D4D4DD'
            }
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#D4D4DD'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#001962'
            }
          },
        },
        color: '#32FBB0',
        title: {
          text: '',
          textStyle: {
            color: '#fff',
            fontSize: '.16rem'
          },
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ]
      }
    };
  },
  mounted() {
    this.year = localStorage.getItem('range1').split(',')[0].split('-')[0];
    this.month = localStorage.getItem('range1').split(',')[0].split('-')[1];
    this.option.title.text = `${this.year}年${this.month}月`;
    this.range = this.getMonthBetween(`${moment().year()}-01`, `${moment().year()}-${localStorage.getItem('month')}`);
    const chartDom = this.$refs.echarts;
    this.myChart = echarts.init(chartDom);
    this.init();
    this.myChart.setOption(this.option);
  },
  methods: {
    init() {
      this.$axios.post('/dashboard/query-user-off-by-month', {
          year: this.range[this.index].split('-')[0],
          month: this.range[this.index].split('-')[1],
      }).then(res => {
        this.option.xAxis.data = res.data.map(item => item.user);
        this.option.series[0].data = res.data.map(item => item.count);
        this.option.title.text = `${this.range[this.index].split('-')[0]}年${this.range[this.index].split('-')[1]}月`;
        this.myChart.setOption(this.option);
      });
    },
    getMonthBetween(start, end) {
      let result = [];
      let s = start.split('-');
      let e = end.split('-');
      let min = new Date();
      let max = new Date();
      min.setFullYear(s[0], s[1]);
      max.setFullYear(e[0], e[1]);
      let curr = min;
      while(curr <= max) {
        let month = curr.getMonth();
        let str = (month === 0 ? curr.getFullYear() - 1 : curr.getFullYear()) + '-' + (month === 0 ? 12 :(month < 10) ? '0' + month : month);
        let s = curr.getFullYear() + '-12';
        if (str === s) {
          str = curr.getFullYear() + '-12';
        }
        result.push(str);
        curr.setMonth(month + 1);
      }
      return result;
    },
    prev() {
      this.index = this.index === 0 ? this.range.length - 1 : this.index - 1;
      this.init();
    },
    next() {
      this.index = this.index === this.range.length - 1 ? 0 : this.index + 1;
      this.init();
    },
  }
}
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  .arrow {
    position: absolute;
    top: 0;
    left: .96rem;
    display: flex;
    z-index: 10;
    .item {
      margin: 0 .05rem;
      background-color: #001B6B;
      display: flex;
      justify-content: center;
      align-items: center;
      width: .27rem;
      height: .27rem;
    }
  }
}
.echarts {
  height: 100%;
}
</style>