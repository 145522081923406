<template>
  <div class="box">
    <div class="title">存量统计(按归属)</div>
    <div class="main" ref="main"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  data() {
    return {
      myChart: null,
      timer: null,
      option: {
        tooltip: {
          trigger: 'item',
          alwaysShowContent: true
        },
        legend: {
          right: 0,
          top: 'center',
          // left: '70%',
          width: '40%',
          icon: 'circle',
          textStyle: {
            fontSize: '.12rem',
            color: '#fff'
          }
        },
        series: [
          {
            name: '存量统计(按归属)',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            right: '40%',
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ]
      },
    };
  },
  mounted() {
    const chartDom = this.$refs.main;
    this.myChart = echarts.init(chartDom);
    this.init();
    this.myChart.setOption(this.option);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    init() {
      this.$axios.get('/dashboard/query-owner-store').then(res => {
        this.option.series[0].data = res.data.map(item => ({name: item.name, value: item.count}));
        this.myChart.setOption(this.option);
        this.showTip();
      });
    },
    showTip() {
      let length = this.option.series[0].data.length;
      let index = 0;
      clearInterval(this.timer);
      this.timer = setInterval(()=>{
        if (index >= length) {
          index = 0
        }
        this.myChart.dispatchAction({
          type: 'highlight',
          dataIndex: index,
        });
        this.myChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: index,
        });
        index++
      },3000)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>