<template>
  <div class="box">
    <div class="horn-top"></div>
    <div class="horn-bottom"></div>
    <div class="title">归属存量排行</div>
    <div class="main">
      <dv-capsule-chart :config="config" :key="key" style="height:100%;" />
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  data() {
    return {
      key: Date.now(),
      config: {
        data: [],
        // colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
        // unit: '单位',
        showValue: true
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.$axios.get('/dashboard/query-owner-store').then(res => {
        this.config.data = res.data.map(item => ({name: item.name, value: item.count}));
        this.key = Date.now();
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.dv-capsule-chart::v-deep {
  & > div {
    justify-content: flex-start;
    & > div:last-child {
      display: none;
    }
  }
  .label-column {
    font-size: .12rem;
    color: #B0B4C3;
    div {
      line-height: 1;
      height: .12rem;
      margin: 5px 0;
    }
  }
  .capsule-item {
    height: .12rem;
    .capsule-item-column {
      height: 100%;
    }
  }
}
</style>