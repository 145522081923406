<template>
  <div id="app">
    <datav />
  </div>
</template>

<script>
import datav from './components/datav/index.vue'
import moment from 'moment'

export default {
  name: 'App',
  components: {
    datav,
  },
  created() {
    const range = [moment().format('YYYY-MM'), moment().add(2, 'M').format('YYYY-MM')];
    if (!localStorage.getItem('time')) {
      localStorage.setItem('time', 5);
    }
    if (!localStorage.getItem('month')) {
      localStorage.setItem('month', 3);
    }
    // if (!localStorage.getItem('range1')) {
    //   localStorage.setItem('range1', range);
    // }
    // if (!localStorage.getItem('range2')) {
    //   localStorage.setItem('range2', range);
    // }
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
html, body{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
html {
  font-size: calc(100vw / 19.2);
}
body {
  font-size: 16px;
}
#app {
  width: 100%;
  height: 100%;
}
</style>
